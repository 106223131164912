import { render, staticRenderFns } from "./VideoProfile.vue?vue&type=template&id=75aba549&scoped=true&"
import script from "./VideoProfile.vue?vue&type=script&lang=js&"
export * from "./VideoProfile.vue?vue&type=script&lang=js&"
import style0 from "./VideoProfile.vue?vue&type=style&index=0&id=75aba549&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75aba549",
  null
  
)

export default component.exports