var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'post-card-custom': _vm.preview },on:{"click":function($event){return _vm.incrementFeaturedPostCount('feed_clickscount')}}},[(_vm.showPost && _vm.showWarning)?_c('div',{staticClass:"profanityClass cs-textstyle-paragraph-small-bold"},[(_vm.post.profanityStatus === 'pendingReview')?_c('span'):_vm._e(),(_vm.post.profanityStatus === 'rejected')?_c('span',[_vm._v(" This post violates our community policies, and is not visible to anyone else. ")]):_vm._e()]):_vm._e(),(_vm.isViewRootPostVisible)?_c('cs-card',{staticClass:"post-card"},[_c('div',{staticClass:"post-mention",attrs:{"slot":"body"},slot:"body"},[(_vm.wasMentioned)?_c('p',{staticClass:"post-mention-message cs-textstyle-paragraph"},[_vm._v(" You were mentioned in a comment by "),_c('span',{directives:[{name:"router-link",rawName:"v-router-link",value:({
            name: 'UProfile',
            params: { username: _vm.formatUsername(_vm.post.user.username) },
          }),expression:"{\n            name: 'UProfile',\n            params: { username: formatUsername(post.user.username) },\n          }"}],staticClass:"ch-user-mention"},[_vm._v(" "+_vm._s(_vm.post.user.username)+" ")])]):_c('p',{staticClass:"post-mention-message cs-textstyle-paragraph"},[_vm._v(" Open post page ")]),_c('cs-button',{attrs:{"size":"small","fill":"outline"},on:{"click":_vm.redirectToPostDetailedPage}},[_vm._v("View Post")])],1)]):_vm._e(),(_vm.showPost)?_c('cs-card',{staticClass:"post-card"},[_c('div',{attrs:{"slot":"body"},slot:"body"},[(_vm.post.sharedPost)?_c('div',{staticClass:"post-card-share-header",class:_vm.preview ? 'no-click' : ''},[_c('div',{staticClass:"cs-textstyle-paragraph-small post-card-share-data"},[(_vm.post.sharedPost)?_c('share-post-header',{attrs:{"post":_vm.post,"preview":_vm.preview}}):_vm._e()],1),(_vm.shareBodyText.length)?_c('div',{staticClass:"\n            cs-textstyle-paragraph\n            post-share-comment\n            pre-line\n            cursor-pointer\n          ",on:{"click":function($event){return _vm.$router.push({
              name: 'PostDetail',
              params: { id: _vm.post.rootPostId },
            })}}},_vm._l((_vm.shareBodyText),function(data,i){return _c('span',{key:i},[(data.type === 'text')?_c('span',[_vm._v(_vm._s(data.word)+" ")]):_vm._e(),(data.type === 'mention')?_c('span',{directives:[{name:"router-link",rawName:"v-router-link",value:({
                name: 'UProfile',
                params: { username: _vm.formatUsername(data.word) },
              }),expression:"{\n                name: 'UProfile',\n                params: { username: formatUsername(data.word) },\n              }"}],staticClass:"ch-user-mention"},[_vm._v(" "+_vm._s(data.word)+" ")]):_vm._e()])}),0):_vm._e(),(_vm.isTranslatingSharedPost)?_c('img',{staticClass:"translate-shared-button",attrs:{"src":require("@/assets/images/translation_icon-orange.svg")},on:{"click":_vm.showTranslatedPost}}):_vm._e(),(_vm.isShowingTranslation && _vm.post.sharedPost)?_c('div',{staticClass:"ch-translation-wrapper"},[(_vm.isHidingTranslateSharedPost)?_c('img',{staticClass:"translate-shared-button",attrs:{"src":require("@/assets/images/translation_icon-orange.svg")},on:{"click":_vm.hideTranslatedPost}}):_vm._e(),_c('div',{staticClass:"post-message cs-textstyle-paragraph message-margin"},_vm._l((_vm.displayTranslatedBody),function(data,i){return _c('span',{key:i + data.word},[(data.type === 'text')?_c('span',[_vm._v(_vm._s(data.word)+" ")]):_vm._e(),(data.type === 'link')?_c('a',{staticClass:"ch-link",attrs:{"href":data.link,"target":"_blank"}},[_vm._v(" "+_vm._s(data.word)+" ")]):_vm._e(),(data.type === 'mention')?_c('span',{directives:[{name:"router-link",rawName:"v-router-link",value:({
                  name: 'UProfile',
                  params: { username: _vm.formatUsername(data.word) },
                }),expression:"{\n                  name: 'UProfile',\n                  params: { username: formatUsername(data.word) },\n                }"}],staticClass:"ch-user-mention"},[_vm._v(_vm._s(data.word)+" ")]):_vm._e()])}),0),(_vm.loadingTranslation)?_c('app-spinner',{staticClass:"ch-spinner",attrs:{"size":"small"}}):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.isNavBarVisible)?_c('div',{staticClass:"ch-post-info",class:_vm.preview ? 'no-click' : ''},[_c('post-header',{attrs:{"post":_vm.targetPost,"preview":_vm.preview,"can-delete":_vm.canDelete}}),(_vm.targetPost.bodyText)?_c('div',{staticClass:"post-message cs-textstyle-paragraph cursor-pointer",on:{"click":_vm.redirectToPostDetailedPage}},_vm._l((_vm.displayBody),function(data,i){return _c('span',{key:i},[(data.type === 'text')?_c('span',[_vm._v(_vm._s(data.word)+" ")]):_vm._e(),(data.type === 'link')?_c('a',{staticClass:"ch-link",attrs:{"href":data.link,"target":"_blank"}},[_vm._v(" "+_vm._s(data.word)+" ")]):_vm._e(),(data.type === 'mention')?_c('span',{directives:[{name:"router-link",rawName:"v-router-link",value:({
                name: 'UProfile',
                params: { username: _vm.formatUsername(data.word) },
              }),expression:"{\n                name: 'UProfile',\n                params: { username: formatUsername(data.word) },\n              }"}],staticClass:"ch-user-mention"},[_vm._v(_vm._s(data.word)+" ")]):_vm._e()])}),0):_vm._e(),(_vm.isTranslatingPost)?_c('img',{staticClass:"translate-button",attrs:{"src":require("@/assets/images/translation_icon-orange.svg")},on:{"click":_vm.showTranslatedPost}}):_vm._e(),(_vm.isShowingTranslation && !_vm.post.sharedPost)?_c('div',{staticClass:"ch-translation-wrapper"},[(_vm.isHidingTranslatePost)?_c('img',{staticClass:"translate-button",attrs:{"src":require("@/assets/images/translation_icon-orange.svg")},on:{"click":_vm.hideTranslatedPost}}):_vm._e(),_c('div',{staticClass:"post-message cs-textstyle-paragraph message-margin"},_vm._l((_vm.displayTranslatedBody),function(data,i){return _c('span',{key:i + data.word},[(data.type === 'text')?_c('span',[_vm._v(_vm._s(data.word)+" ")]):_vm._e(),(data.type === 'link')?_c('a',{staticClass:"ch-link",attrs:{"href":data.link,"target":"_blank"}},[_vm._v(" "+_vm._s(data.word)+" ")]):_vm._e(),(data.type === 'mention')?_c('span',{directives:[{name:"router-link",rawName:"v-router-link",value:({
                  name: 'UProfile',
                  params: { username: _vm.formatUsername(data.word) },
                }),expression:"{\n                  name: 'UProfile',\n                  params: { username: formatUsername(data.word) },\n                }"}],staticClass:"ch-user-mention"},[_vm._v(_vm._s(data.word)+" ")]):_vm._e()])}),0),(_vm.loadingTranslation)?_c('app-spinner',{staticClass:"ch-spinner",attrs:{"size":"small"}}):_vm._e()],1):_vm._e(),(_vm.targetPost.tags && _vm.targetPost.tags.length)?_c('post-tags',{attrs:{"tags":_vm.targetPost.tags}}):_vm._e(),(_vm.targetPost.location)?_c('cs-small-tag',{attrs:{"variant":"default"}},[_c('i',{staticClass:"cs-icons-marker-filled"}),_vm._v(" "+_vm._s(_vm.targetPost.location)+" ")]):_vm._e()],1):_vm._e(),(_vm.isMediaPost && _vm.isNavBarVisible)?_c('post-media',{staticClass:"media-container",attrs:{"link-preview":_vm.targetPost.linkPreview,"images":_vm.targetPost.images,"document":_vm.targetPost.document,"video":_vm.targetPost.video,"post-id":_vm.targetPost.id,"autoplay":_vm.autoplay}}):_vm._e(),(!_vm.preview)?_c('post-response',{attrs:{"post":_vm.targetPost}}):_vm._e()],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }